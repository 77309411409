import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import '../../design/home.scss';
import SalesPersonCard from './SalsePersonCard';

export default function SalesLooserTabholder() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
        <Box sx={{ width: '100%', typography: 'SalesLooserTabholder' }} className='SalseLooserTabHolder'>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={value} 
                    onChange={handleChange} 
                    centered
                >
                    <Tab className='graphMenuText' label="Daily" value="1" />
                    <Tab className='graphMenuText' label="WEEKLY" value="2" />
                    <Tab className='graphMenuText' label="MONTHLY" value="3" />
                </Tabs>
            </Box>
              <TabPanel className="SalesPersonCardHolderOne" value="1">
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
              </TabPanel>
              <TabPanel className="SalesPersonCardHolderTwo" value="2">
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
              </TabPanel>
              <TabPanel className="SalesPersonCardHolderThree" value="3">
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
                <SalesPersonCard/>
              </TabPanel>
          </TabContext>
        </Box>
  );
}


