import * as React from 'react';
import '../../design/home.scss';
import Box from '@mui/material/Box';
import BestSalesWinnerTabholder from './BestSalesWinnerTabHolder';
import HomeContainerHeadline from './HomeContainerHeadline';

const BestSalesContainer = () => {
    return (
        <Box className="HomeContainer HomeContainerFirst">
            <HomeContainerHeadline title="Best Salse Winner" />
            <BestSalesWinnerTabholder/>
        </Box>
    );
};

export default BestSalesContainer;