import React, {useEffect} from 'react';
import '../design/login.css';
import Swal from 'sweetalert2';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import ForgotPassword from "./ForgotPassword";
import Form from "./loginForm";

const Login = () => {
    const [qrModalIsOpen, setQrModalIsOpen] = React.useState(false);

    function swalFunction() {
        document.getElementById('QrButton').style.display = 'none';
        document.getElementById('mainDivOfLogin').style.display = 'none';
        setQrModalIsOpen(true);
        Swal.fire({
            title: '<p style="text-align: center;font-size: 14pt;color: #610329">LOGIN WITH QR CODE <br> <span style="font-size: 20pt">OR</span></p>',
            width: 320,
            imageUrl: 'https://unsplash.it/400/200',
            imageWidth: 280,
            imageHeight: 280,
            imageAlt: 'Custom image',
            confirmButtonText: 'Manual Login',
            confirmButtonColor: '#d1213c',
            allowOutsideClick: false,
            backdrop: `
            rgba(69, 164, 247,0.4)
            url("/images/nyan-cat.gif")
            left top
            no-repeat
          `
        }).then(function () {
            document.getElementById('QrButton').style.display = 'block';
            document.getElementById('mainDivOfLogin').style.display = 'block';
        });
    }

    useEffect(() => {
        swalFunction();
    },[]);

    return (
        <div className="custom-login-box login-box-custom">
            <div className="custom-card card-primary" id="mainDivOfLogin">
                <button
                    type="button"
                    onClick={swalFunction}
                    id="QrButton"
                    className={qrModalIsOpen ? 'displayBlock' : 'displayNone'}
                    style={{
                        position: 'absolute',
                        width: 40,
                        marginLeft: -30,
                        zIndex: 1,
                        marginTop: -20,
                        borderBottomRightRadius: 10,
                        borderTopLeftRadius: 15,
                        backgroundColor: 'white',
                        display: 'none',
                        border: 'red 2px solid'
                    }}
                >
                    <QrCodeScannerRoundedIcon />
                </button>
                <div className="card-header text-center-custom">
                    <div className="h1">
                        <b className="classForBrandText">NEWAYS</b>
                        <span className="classFor3">3</span>
                    </div>
                </div>
                <div className="custom-card-body" id="loginDivHolder">
                    <p className="login-box-msg">Login to start your session</p>
                    <Form />
                </div>
                <ForgotPassword />
            </div>
        </div>
    );
};

export default Login;
