import React, { useState} from "react";
import '../design/home.scss';
import Modal from 'react-modal';
import IconButton from '@mui/material/IconButton';
import MenuIcon from './MenuIcon';
import Box from '@mui/material/Box';
import ModalCancelIcon from "./ModalCancelIcon";
import MobileSideBar from "./MobileSideBar";

const MobileSideMenu = () => {

    const [thisModalIsOpen,setModalIsOpen] = useState(false);

    function openModal() {
        thisModalIsOpen ? setModalIsOpen(false) : setModalIsOpen(true);
    }

    return (
        <Box>
            <Modal
                isOpen={thisModalIsOpen}
                contentLabel="Example Modal"
                className="Modal"
                transparent={false}
            >
                <Box className="MobileSideMenuHolder">
                    <MobileSideBar/>
                </Box>
            </Modal>
            <Box className={ thisModalIsOpen ? 'displayNone' : 'displayBlock'}>
                <IconButton onClick={openModal} >
                    <MenuIcon />
                </IconButton>
            </Box>
            <Box className={ thisModalIsOpen ? 'displayBlock' : 'displayNone'}>
                <IconButton onClick={openModal} >
                    <ModalCancelIcon/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default MobileSideMenu;
