import * as React from 'react';
import '../design/home.scss';
import Box from '@mui/material/Box';
import LogoutMenuDropdown from './LogoutMenuDropdown';
import GraphBoard from './MainComponents/GraphBoard';
import NotificationIcon from './NotificationIcon';
import Sidebar from "./sidebar";
import TopBar from './topbar';

const Dashboard = () => {
    return (
        <Box className="Home">
                <Sidebar/>
                <Box className="Main">
                    <LogoutMenuDropdown/>
                    <NotificationIcon/>
                    <TopBar/>
                    <GraphBoard/>
                </Box>
        </Box>
    );
};

export default Dashboard;