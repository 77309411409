import * as React from 'react';
import '../../design/home.scss';
import BestBranchTabHolder from './BestBranchTabHolder';
import HomeContainerHeadline from './HomeContainerHeadline';
import Box from '@mui/material/Box';

const BestBranchList = () => {
    return (
        <Box className="HomeContainer">
            <HomeContainerHeadline title="Best Branch List" />
            <BestBranchTabHolder/>
        </Box>
    );
};

export default BestBranchList;