import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import '../../design/graph-board.scss';
import BottomNavigation from '@mui/material/BottomNavigation';
import DailyGraphHolder from './Graphs/DailyGraphHolder';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
        <Box sx={{ width: '98%', bgcolor: 'background.paper' }} className='graphBoardMain'>
          <TabContext value={value} sx={{width:'100%'}}>  
            <TabPanel value="1" className="DailyGraphHolderPanel">
              <DailyGraphHolder/>
            </TabPanel>
            <TabPanel value="2" className="DailyGraphHolderPanel">
              <DailyGraphHolder/>
            </TabPanel>
            <TabPanel value="3" className="DailyGraphHolderPanel">
              <DailyGraphHolder/>
            </TabPanel>
          </TabContext>
            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }
                }
                >
                      <Tabs value={value} 
                            onChange={handleChange} 
                            centered
                            indicatorColor='secondary'
                            selectionFollowsFocus={true}
                        >
                        <Tab className='graphMenuText' label="Daily" value="1" />
                        <Tab className='graphMenuText' label="Monthly" value="2" />
                        <Tab className='graphMenuText' label="Yearly" value="3" />
                      </Tabs>
                </BottomNavigation>
            </Box>
        </Box>
  );
}




