import * as React from 'react';
import '../design/home.scss';
import BestBranchList from './HomeContainers/BestBranchLists';
import BestSalesContainer from './HomeContainers/BestSalesContainer';
import SalesLooserContainer from './HomeContainers/SalesLooserContainer';
import LogoutMenuDropdown from './LogoutMenuDropdown';
import NotificationIcon from './NotificationIcon';
import Sidebar from "./sidebar";
import TopBar from './topbar';
import Box from '@mui/material/Box';

const Home = () => {
    return (
        <Box className="Home">
            <Sidebar/>
            <Box className="Main">
                <LogoutMenuDropdown/>
                <NotificationIcon/>
                <TopBar/>
                <Box className='HomeContainersHolder'>
                    <BestSalesContainer/>
                    <BestBranchList/>
                    <SalesLooserContainer/>
                </Box>
            </Box>
        </Box>
    );
};

export default Home;