import './App.css';
import Login from "./auth/Login";
import PublicRoute from "./auth/PublicRoute";
import PrivateRoute from "./auth/PrivateRoute";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./Home/Home";
import Dashboard from './Home/Dashboard';
import Profile from './Home/MainComponents/Profile';
import ManageBranch from './Create/BranchBed_etc/ManageBranch';
import ManageRoles from './Create/HRM/ManageRoles';
import Booking from './Booking/Booking';

    const App = () => {
        //on window 
        // window.onunload  = () => {
        //     localStorage.clear();
        // }
        return (
            <Router>
                <Routes>
                    <Route path='/' element={<PrivateRoute/>}>
                        <Route exact path='/' element={<Home/>}/>
                    </Route>
                    <Route path='/Dashboard' element={<PrivateRoute/>}>
                        <Route exact path='/Dashboard' element={<Dashboard/>}/>
                    </Route>
                    <Route path='/Profile' element={<PrivateRoute/>}>
                        <Route exact path='/Profile' element={<Profile/>}/>
                    </Route>
                    <Route path='/ManageBranch' element={<PrivateRoute/>}>
                        <Route exact path='/ManageBranch' element={<ManageBranch/>}/>
                    </Route>
                    <Route path='/ManageRoles' element={<PrivateRoute/>}>
                        <Route exact path='/ManageRoles' element={<ManageRoles/>}/>
                    </Route>
                    <Route path='/Booking' element={<PrivateRoute/>}>
                        <Route exact path='/Booking' element={<Booking/>}/>
                    </Route>
                    
                    <Route path='/Login' element={<PublicRoute/>}>
                        <Route exact path='/Login' element={<Login/>}/>
                    </Route>
                </Routes>
            </Router>

        );
    };

export default App;
