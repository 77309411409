import React, { useState, useEffect } from "react";

import "../design/loginForm.scss";
import "../design/login.css";
const FrogtPasswordForm = () => {
    const intialValues = { email: "", password: "", otp: "" };

    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetPasswordcounter, setresetPasswordcounter] = useState(0);
    const resetPasswordOtpMessage = `OTP has been sent! Resend in ${resetPasswordcounter}`;
    const [isForgotPasswordOtpStatus, setForgotPasswordOtpStatus] =
        useState(false);

    useEffect(() => {
        if(resetPasswordcounter > 0){
            document.getElementById("otpButtonForgotPassword").disabled = true;
        }else{
            document.getElementById("otpButtonForgotPassword").disabled = false;
        }
    },[resetPasswordcounter])

    useEffect(() => {
        const timer =
            resetPasswordcounter > 0 &&
            setInterval(
                () => setresetPasswordcounter(resetPasswordcounter - 1),
                1000
            );
        if (resetPasswordcounter === 0) {
            setForgotPasswordOtpStatus(false);
        }
        return () => clearInterval(timer);
    }, [resetPasswordcounter]);

    const submit = () => {
        console.log(formValues);
    };

    function setPasswordResetOtpButtonStatus() {
        setForgotPasswordOtpStatus(true);
        setresetPasswordcounter(30);
    }

    //input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    //form validation handler
    const validate = (values) => {
        let errors = {};

        if (!values.password) {
            errors.password = "Password can't be empty!";
        }else if (values.password.length < 8) {
            errors.password = "Confirm Password must be more than 7 characters!";
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = "Confirm Password can't be blank";
        } else if (values.confirmPassword.length < 8) {
            errors.confirmPassword = "Confirm Password must be more than 7 characters!";
        }else if(values.password !== values.confirmPassword){
            errors.confirmPassword = "Password and Confirm Password does not match!";
        }

        if (!values.otp) {
            errors.otp = "OTP can't be blank!";
        }

        if(errors.password) {
            alert(errors.password.length)
        } else if(errors.confirmPassword){
            alert(errors.confirmPassword.length)
        } else if(errors.otp.length){
            alert(errors.otp.length)
        }

        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submit();
        }
    }, [formErrors]);

    return (
        <div className="container">
            {Object.keys(formErrors).length === 0 && isSubmitting && (
                <span className="success-msg">Form submitted successfully</span>
            )}
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-row">
                    <label htmlFor="email" className="inputLabel">Password</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={formValues.password}
                        onChange={handleChange}
                        className={formErrors.password && "input-error"}
                    />
                    {formErrors.password && (
                        <span className="error">{formErrors.password}</span>
                    )}
                </div>

                <div className="form-row">
                    <label htmlFor="password" className="inputLabel">Confirm Password</label>
                    <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        value={formValues.confirmPassword}
                        onChange={handleChange}
                        className={formErrors.confirmPassword && "input-error"}
                    />
                    {formErrors.confirmPassword && (
                        <span className="error">{formErrors.confirmPassword}</span>
                    )}
                </div>

                <div htmlFor="otp" className="inputLabel otpInputLabel">OTP</div>
                <div className="form-row forgotPasswordOtpHolder">
                    <div className="forgotPasswordOtpButtonHolder">
                        <button className="resetGetOtpButton"
                                id="otpButtonForgotPassword"
                                type="button"
                                onClick={ () => setresetPasswordcounter(30)}>
                            Get OTP
                        </button>
                    </div>
                    <div className="forgotPasswordOtpInputHolder">
                        <input
                            type="text"
                            name="otp"
                            id="otp"
                            value={formValues.otp}
                            onChange={handleChange}
                            className={formErrors.otp && "input-error"}
                        />
                        <p style={{textAlign:'center',marginTop:'10px',}}>
                            {formErrors.otp && (
                                <span className="error">{formErrors.otp}</span>
                            )}
                        </p>
                        <div style={{paddingTop:20,marginLeft:'-15px'}}>
                            <p className={resetPasswordcounter === 0 ? 'displayNone otpCounterMessage' : 'displayBlock otpCounterMessage'}>
                                {resetPasswordOtpMessage}
                            </p>
                        </div>
                    </div>
                </div>

                <button type="submit" className="resetPasswordSubmitButton">RESET PASSWORD</button>
            </form>
        </div>
    );
};

export default FrogtPasswordForm;
