import React, { useState,useEffect } from "react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import LogoutMenuDropdown from "../../Home/LogoutMenuDropdown";
import NotificationIcon from "../../Home/NotificationIcon";
import TopBar from "../../Home/topbar";
import SideBar from "../../Home/sidebar";
import Typography from '@mui/material/Typography';
import '../../design/create.scss';
import '../../design/home.scss';
import axios from "axios";
import PageLoading from "../../Home/PageLoading";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PreviewIcon from '@mui/icons-material/Preview';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

export default function  ManageRoles() {
    const backendDomaiOrIpAddress = localStorage.getItem('backendDomaiOrIpAddress');
    const intialValues = { permissionName: "", roleName: ""};
    const [formValues, setFormValues] = useState(intialValues);
    const [tableData,setTableData] =  useState();
    const userToken = localStorage.getItem('EmployeeToken');
    const [tableDataStatus,setTableDataStatus] = useState(false);
    const [permissionModalName,setpermissionModalName] = useState('');
    const [erroMessage,SetErrorMessage] = useState(null);
    const [roleErroMessage,setroleErroMessage] = useState(null);
    const [roleReload,setRoleReload] = useState('');
    const [permissionsByRole,setPermissionsByRole] = useState(null);

    const [createPermissionModalOpen, setCreatePermissionModalOpen] = useState(false);
    const PermissionModalOpen = () => setCreatePermissionModalOpen(true);
    const PermissionModalClose = () => setCreatePermissionModalOpen(false);

    const [createRoleModalOpen, setcreateRoleModalOpen] = useState(false);
    const RoleModalOpen = () => setcreateRoleModalOpen(true);
    const createRoleModalClose = () => setcreateRoleModalOpen(false);

    const [roleWisePermissionsOpen, setRoleWisePermissionsOpen] = useState(false);
    const PermissionsModalOpen = (permissionName) => {
        setRoleWisePermissionsOpen(true);
        setpermissionModalName(permissionName);
    }
    const PermissionsModalClose = () => setRoleWisePermissionsOpen(false);

    const [lastRoleId, setLastRoleId] = useState(null);

    //input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };


    useEffect(() => {
        axios.get(backendDomaiOrIpAddress+'/super_home_laravel/public/api/role', { headers: { Authorization: userToken } })
            .then(response => {
                setTableData(response.data);
                setTableDataStatus(true)
            })
            .catch((error) => {
                setTableDataStatus(false)
            });
    },[roleReload]);


    const createNewPermmmission = () => {
        axios.post(backendDomaiOrIpAddress+'/super_home_laravel/public/api/permission', {
            name: formValues.permissionName,
        },
        { headers: { Authorization: userToken } 
        })
        .then(function (response) {
            PermissionModalClose()
            successAlert(formValues.permissionName + ' Permission Created Successfully!')
            setFormValues(intialValues)
            SetErrorMessage(null)
        })
        .catch(function (error) {
            if(error.response.status == 422){
                SetErrorMessage(error.response.data.errors.name);
            }else{
                SetErrorMessage('This ' + error.response.data.message);
            }
        });
    }


    const createNewRole = () => {
        axios.post(backendDomaiOrIpAddress+'/super_home_laravel/public/api/role', {
            name: formValues.roleName,
        },
        { headers: { Authorization: userToken } 
        })
        .then(function (response) {
            createRoleModalClose()
            successAlert(formValues.roleName + ' Role Created Successfully!' )
            setFormValues(intialValues)
            setroleErroMessage(null)
            setRoleReload(formValues.roleName)
        })
        .catch(function (error) {
            if(error.response.status == 422){
                setroleErroMessage(error.response.data.errors.name);
            }else{
                setroleErroMessage('This ' + error.response.data.message);
            }
        });
    }

    const deleteRole = (roleName,id) => {
        axios.delete(backendDomaiOrIpAddress+'/super_home_laravel/public/api/role/'+id,
        { 
            headers: { Authorization: userToken },
            // params: {role: roleName}
        })
        .then(function (response) {
            createRoleModalClose()
            successAlert(roleName + ' Role Deleted Successfully!' )
            setRoleReload(roleName)
        })
        .catch(function (error) {
            if(error.response.status == 422){
                setroleErroMessage(error.response.data.errors.name);
            }else{
                setroleErroMessage('This ' + error.response.data.message);
            }
        });
    }

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: pink[500],
          '&:hover': {
            backgroundColor: alpha(pink[500], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: pink[500],
        },
      }));

    const label = { inputProps: { 'aria-label': 'Switch demo' } };
     
    function confirmDelete (name,id) {
        Swal.fire({
            title: "Sure Delete <span style='font-weight:900;color:red;'>"+name+"</span> ?",
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRole(name,id);
            }
        })
    }

    function AddPermissionMenu() {
        return (
            <Box className="AddPermissionMenu">
                <Box xs={{ display: 'flex', alignItems: 'left', textAlign: 'center', width: '60px', }}>
                    <Tooltip title="Create Permision">
                        <IconButton size="small" onClick={PermissionModalOpen}>
                            <Avatar className="topBarSideMenu">
                                <AddCircleOutlineIcon/>
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        );
    }

    function AddRoleMenu() {
        return (
            <Box className="AddRoleMenu">
                <Box xs={{ display: 'flex', alignItems: 'left', textAlign: 'center', width: '60px', }}>
                    <Tooltip title="Create Role">
                        <IconButton size="small" onClick={RoleModalOpen}>
                            <Avatar className="topBarSideMenu">
                                <AddCircleOutlineIcon/>
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        );
    }

    function successAlert(value) {
        Swal.fire({
            icon: 'success',
            title: 'WOW...',
            text: value,
            })
    }

    const getPermissionsByRole = (id,name) => {
        setTableDataStatus(false);
        setLastRoleId(id);
        axios.get(backendDomaiOrIpAddress+'/super_home_laravel/public/api/permission-role/'+id, 
            { headers: { Authorization: userToken } 
            }).then(response => {
                setPermissionsByRole(response.data);
                PermissionsModalOpen(name);
                setTableDataStatus(true)
            })
            .catch((error) => {
        });
    }

    function switchOnchange(permission_id,role_id){
        let swhitchStatus = document.getElementById('switchId'+permission_id).checked;
        var aSwitchStatus = 0;
        if(swhitchStatus == true){
            aSwitchStatus = 1;
        }else{
            aSwitchStatus = 0;
        }
        
        axios.post(backendDomaiOrIpAddress+'/super_home_laravel/public/api/role-to-permission', {
            role_id: role_id,
            permission_id: permission_id,
            status: aSwitchStatus,
        },
        { headers: { Authorization: userToken } 
        })
        .then(function (response) {
            if(response.status == 201){
                getPermissionsByRole(role_id,permissionModalName);
            }
        })
        .catch(function (error) {
            
        });
    }
    
      
    return(
        tableDataStatus ? 
            <Box className="Home">
                <SideBar/>
                <Box className="Main">
                    <AddRoleMenu/>
                    <AddPermissionMenu/>
                    <LogoutMenuDropdown/>
                    <NotificationIcon/>
                    <TopBar/>
                    <Box className="ManageRolesMainBox">
                        <Typography variant="h4" gutterBottom component="div">
                            Roles
                        </Typography>
                        <Box className="ManagePermissionCardHolder">
                            {tableData.map((row) => (
                                <Box key={row.id} className="ManagePermissionCard">
                                    <Box>
                                        <Box className="ManagePermissionCardTopPortion">
                                            <Avatar sx={{ bgcolor: 'red' }}>
                                            {row.id}
                                            </Avatar>
                                            <GreenSwitch {...label} defaultChecked />
                                        </Box>
                                        <Box className="ManagePermissionCardMiddleTexts">
                                            <Typography variant="overline">{row.name}</Typography>
                                            <Typography variant="overline">{row.guard_name}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="ManagePermissionBottomButtonHolder">
                                        <Button
                                            color="warning"
                                            endIcon={<PreviewIcon />}
                                            variant="contained"
                                            className="ManagePermissionCardButton"
                                            onClick={() => {
                                                confirmDelete(row.name,row.id)
                                              }}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            color="secondary"
                                            endIcon={<PreviewIcon />}
                                            variant="contained"
                                            className="ManagePermissionCardButton"
                                            onClick={() => {
                                                getPermissionsByRole(row.id,row.name)
                                              }}
                                        >
                                            Permissions
                                        </Button>
                                    </Box>
                                </Box>
                            ))}

                            {/* create permission modal start */}
                            <Modal
                                open={createPermissionModalOpen}
                                onClose={PermissionModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box className="addPermissionModalMAinDiv">
                                    <p className="addPermissionModalMAinDivHeadline">Create New Permission</p>
                                    <Box className="addPermissionModalFormHolder">
                                        <input
                                            className="createInputClass"
                                            name="permissionName"
                                            id="permissionName"
                                            value={formValues.permissionName}
                                            onChange={handleChange}
                                            label = "Permission Name" 
                                            placeholder="New Permission"
                                        />
                                        <p>{erroMessage}</p>
                                        <Button
                                            endIcon={<AddCircleOutlineIcon />}
                                            variant="contained"
                                            className="addPermissionSubmitButton"
                                            onClick={() => {
                                                createNewPermmmission()
                                            }}
                                        >
                                            Create
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                            {/* create permission modal end */}

                            {/* create role modal start */}
                            <Modal
                                open={createRoleModalOpen}
                                onClose={createRoleModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box className="addPermissionModalMAinDiv">
                                    <p className="addPermissionModalMAinDivHeadline">Create New Role</p>
                                    <Box className="addPermissionModalFormHolder">
                                         <input
                                            className="createInputClass"
                                            name="roleName"
                                            id="roleName"
                                            value={formValues.roleName}
                                            onChange={handleChange}
                                            label = "Role Name" 
                                            placeholder="New Role"
                                        />
                                        <p>{roleErroMessage}</p>
                                        <Button
                                            endIcon={<AddCircleOutlineIcon />}
                                            variant="contained"
                                            className="addPermissionSubmitButton"
                                            onClick={() => {
                                                createNewRole()
                                            }}
                                        >
                                            Create
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                            {/* create role modal end */}

                            {/* role wise permissions modal start */}
                            <Modal
                                open={roleWisePermissionsOpen}
                                onClose={PermissionsModalClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box>
                                    <p className="ManageRolesPermissionModalHeadline">
                                        {permissionModalName} Permissions
                                        <Box className="closePermissionModal">
                                            <Box xs={{ display: 'flex', alignItems: 'left', textAlign: 'center', width: '60px', }}>
                                                <Tooltip title="Close">
                                                    <IconButton size="small" onClick={PermissionsModalClose}>
                                                        <Avatar className="topBarSideMenu">
                                                            <CloseIcon/>
                                                        </Avatar>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </p>
                                    <Box className="ManageRolesPermissionModalMAinDiv">
                                        { permissionsByRole?
                                            permissionsByRole.map((row) => (
                                                <Typography key={row.id} variant="overline">
                                                    <GreenSwitch 
                                                        {...label} 
                                                        defaultChecked = {row.role_id == null ? false : true} 
                                                        id={'switchId'+row.id} 
                                                        onChange = {() => {
                                                            switchOnchange(row.id,lastRoleId)
                                                        }}
                                                        /> 
                                                        {row.name}
                                                </Typography>
                                            ))
                                            : ''
                                        }
                                    </Box>
                                </Box>
                            </Modal>
                            {/* role wise permissions modal end */}

                        </Box>
                    </Box>
                </Box>
            </Box>
        : <PageLoading/>
    )
}
