import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import '../../design/home.scss';

export default function SalesPersonCard(props) {
  return (
        <Box className='SalesPersonCard'>
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: '150px', height: '150px',marginRight:'auto',marginLeft:'auto' }}
            />
            <Box className='SalesPersonCardTextHolder'>
                <p className='SalesPersonCardText'>Muhammad Musfiqur Rahman</p>
                <p className='SalesPersonCardText'>Booked: </p>
                <p className='SalesPersonCardText'>Point: </p>
                <p className='SalesPersonCardText'>Date: </p>
            </Box>
        </Box>
  );
}
