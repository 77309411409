import * as React from 'react';
import '../../design/home.scss';
import Box from '@mui/material/Box';
import HomeContainerHeadline from './HomeContainerHeadline';
import SalesLooserTabholder from './SalesLooserTabHolder';

const SalesLooserContainer = () => {
    return (
        <Box className="HomeContainer">
            <HomeContainerHeadline title="Salse Loosers"/>
            <SalesLooserTabholder/>
        </Box>
    );
};

export default SalesLooserContainer;