import * as React from 'react';
import '../../design/home.scss';
import Box from '@mui/material/Box';

const HomeContainerHeadline = (props) => {
    return (
        <Box className="HomeContainerHeadline">
            {props.title}
        </Box>
    );
};

export default HomeContainerHeadline;