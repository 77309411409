import '../../../design/graph-board.scss';
import BarChart from './BarChart';
import BarChartHorizontal from './BarChartHorizontal';
import PieChart from './PieChart';
import Box from '@mui/material/Box';

export default function DailyGraphHolder(){
    return(
        <Box className="DailyGraphHolder">
            <PieChart/>
            <BarChart/>
            <BarChartHorizontal/>
            <PieChart/>
            <BarChart/>
            <BarChartHorizontal/>
            <PieChart/>
            <BarChart/>
            <BarChartHorizontal/>
        </Box>
    )
}