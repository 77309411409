import React, { useState, useEffect } from "react";
import "../design/loginForm.scss";
import "../design/login.css";
import {useNavigate } from 'react-router-dom';
import PageLoading from "../Home/PageLoading";
import Button from '@mui/material/Button';
import {apiClient} from '../Variables/Values';

const Form = () => {
    const intialValues = { email: "", password: "", otp: "" };
    const [isAuthLoading, setAuthLoading] = useState(false);
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [counter, setCounter] = useState(0);
    const [otpButtonStatus, setOtpStatusButton] = useState(false);
    const OtpMessage = `OTP has been sent! Resend in ${counter}`;
    const [canSendOtp,setcanSendOtp] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        if(counter === 0){
            setOtpStatusButton(false)
        }else{
            setOtpStatusButton(true)
        }
        const timer =
            counter > 0 &&
            setInterval(
                () => setCounter(counter - 1),
                1000
            );
        return () => clearInterval(timer);
    }, [counter]);

    // const submit = (values) => {
    // };

    function login(){
        setAuthLoading(true);
        apiClient.get('sanctum/csrf-cookie')
        .then(() => 
            apiClient.post('check_otp', {
                employee_id: formValues.employeeId,
                password: formValues.password,
                otp: formValues.otp,
            }))
          .then((response) => {
            if(response.data.status == true) {
                localStorage.clear();
                localStorage.setItem('EmployeeName',response.data.name);
                localStorage.setItem('EmployeeToken',formValues.otp);
                localStorage.setItem('EmployeeId',response.data.employee_id);
                navigate("/", { replace: true });
                setAuthLoading(false);
            }
          })
          .catch(function (error) {
            setAuthLoading(false);
          });
        }

    //input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //form submission handler
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
        login();
    };

    //form validation handler
    const validate = (values) => {
        let errors = {};

        if (!values.employeeId) {
            errors.employeeId = "Employee ID can't be empty!";
        }

        if (!values.password) {
            errors.password = "Password can't be blank";
        } else if (values.password.length < 8) {
            errors.password = "Password must be more than 7 characters!";
        }

        if (!values.otp) {
            errors.otp = "OTP can't be blank!";
        }
            return errors;
    };

    // useEffect(() => {
    //     if (Object.keys(formErrors).length === 0 && isSubmitting) {
    //         //login(formValues);
    //     }
    // }, [formErrors]);

    function sendOtp(){
            apiClient.get('sanctum/csrf-cookie')
        .then((response) => apiClient.post('login', { 
                employee_id: formValues.employeeId,
                password: formValues.password
            }))
        .then((response) => {
            if(response.data.status == true) {
                alert('hello')
                confirmSendOtp();
            }else{
                setcanSendOtp(false);
                setCounter(0);
            }
        }).catch(error => {
        //Won't catch the error if I enter wrong credentials
            //setError('error')
        })
    }

    function confirmSendOtp(){
        apiClient.get('sanctum/csrf-cookie')
        .then( () => apiClient.post('send_otp', {
                employee_id: formValues.employeeId,
                password: formValues.password
            }))
        .then((response) => {
                if(response.data.status == true){
                    localStorage.clear();
                    localStorage.setItem('otpGot',response.data.otp)
                    alert(response.data.otp)
                    setCounter(30);
                    setcanSendOtp(true);
                }else{
                    setcanSendOtp(false);
                    setCounter(0);
                }
            })
    }

    return (
        isAuthLoading === true ? <PageLoading/> :
        <div className="container">
            {Object.keys(formErrors).length === 0 && isSubmitting && (
                <span className="success-msg">Form submitted successfully</span>
            )}
            <form onSubmit={handleSubmit} noValidate>
                <div className="form-row">
                    <label htmlFor="email" className="inputLabel">EMPLOYEE ID</label>
                    <input
                        type="number"
                        name="employeeId"
                        id="employeeId"
                        value={formValues.employeeId}
                        onChange={handleChange}
                        className={formErrors.employeeId && "input-error"}
                    />
                    {formErrors.employeeId && (
                        <span className="error">{formErrors.employeeId}</span>
                    )}
                </div>

                <div className="form-row">
                    <label htmlFor="password" className="inputLabel">PASSWORD</label>
                    <input
                        type="password"
                        name="password"
                        id="password"
                        value={formValues.password}
                        onChange={handleChange}
                        className={formErrors.password && "input-error"}
                    />
                    {formErrors.password && (
                        <span className="error">{formErrors.password}</span>
                    )}
                </div>

                <div htmlFor="otp" className="inputLabel otpInputLabel">OTP</div>
                <div className="form-row forgotPasswordOtpHolder">
                    <div className="forgotPasswordOtpButtonHolder">
                        <Button className="resetGetOtpButton"
                                id="otpButton"
                                type="button"
                                onClick={sendOtp}
                                disabled={otpButtonStatus}
                        >
                            Get OTP
                        </Button>
                    </div>
                    <div className="forgotPasswordOtpInputHolder">
                        <input
                            type="text"
                            name="otp"
                            id="otp"
                            value={formValues.otp}
                            onChange={handleChange}
                            className={formErrors.otp && "input-error"}
                        />
                        <p>
                            {formErrors.otp && (
                                <span className="error">{formErrors.otp}</span>
                            )}
                            {canSendOtp === false ? <span className="error"> Invalid ID or Password!</span> : ''}
                        </p>
                        <div style={{paddingTop:20,marginLeft:'-15px'}}>
                            <p
                                className={counter === 0 ? 'displayNone otpCounterMessage' : 'displayBlock otpCounterMessage'}
                                style={{marginLeft:'-40px'}}
                            >
                                {OtpMessage}
                            </p>
                        </div>
                    </div>
                </div>

                <Button type="submit">Sign In</Button>
            </form>
        </div>
    );
};

export default Form;
