import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import '../../design/home.scss';

export default function BestBranchTree() {
  return (
    <Timeline position="alternate">

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Shahbagh Branch
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: 126, height: 126 }}
                className='BestBranchTreeAvater'
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0', py: '1vh', px: 2 }}>
          <Typography variant="subtitle2" component="span">
            Name
          </Typography>
          <Typography variant="subtitle2">Degignation</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Shahbagh Branch
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: 126, height: 126 }}
                className='BestBranchTreeAvater'
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0', py: '1vh', px: 2 }}>
          <Typography variant="subtitle2" component="span">
            Name
          </Typography>
          <Typography variant="subtitle2">Degignation</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Shahbagh Branch
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: 126, height: 126 }}
                className='BestBranchTreeAvater'
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0', py: '1vh', px: 2 }}>
          <Typography variant="subtitle2" component="span">
            Name
          </Typography>
          <Typography variant="subtitle2">Degignation</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Shahbagh Branch
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: 126, height: 126 }}
                className='BestBranchTreeAvater'
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0', py: '1vh', px: 2 }}>
          <Typography variant="subtitle2" component="span">
            Name
          </Typography>
          <Typography variant="subtitle2">Degignation</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Shahbagh Branch
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: 126, height: 126 }}
                className='BestBranchTreeAvater'
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0', py: '1vh', px: 2 }}>
          <Typography variant="subtitle2" component="span">
            Name
          </Typography>
          <Typography variant="subtitle2">Degignation</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          Shahbagh Branch
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Avatar
                alt="Remy Sharp"
                src="https://i2-prod.walesonline.co.uk/news/uk-news/article18917348.ece/ALTERNATES/s615b/0_254870178.jpg"
                sx={{ width: 126, height: 126 }}
                className='BestBranchTreeAvater'
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: 'auto 0', py: '1vh', px: 2 }}>
          <Typography variant="subtitle2" component="span">
            Name
          </Typography>
          <Typography variant="subtitle2">Degignation</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
          <Typography variant="subtitle2">Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
