import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import '../design/home.scss';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ModalCancelIcon() {

    return (
        <div className="MenuIcon">
            <Box xs={{ display: 'flex', alignItems: 'right', textAlign: 'center', width: '60px', }}>
                <Tooltip title="Account settings">
                    <IconButton size="small" >
                        <Avatar className="topBarSideMenu">
                            <CancelIcon/>
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    );
}
