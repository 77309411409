import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
    const isLoggedIn = localStorage.getItem('EmployeeToken');

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isLoggedIn == null ?  <Navigate to="/Login" /> : <Outlet /> ;
}

export default PrivateRoute;
