import '../design/home.scss';
import LogoutMenuDropdown from '../PhoneMenu/LogoutMenuDropdown';
import MobileSideMenu from '../PhoneMenu/MobileSideMenu';
import NotificationIcon from '../PhoneMenu/NotificationIcon';

const TopBar = () => {
    return(
        <div className="topBar">
            <MobileSideMenu/>
            <NotificationIcon/>
            <LogoutMenuDropdown/>
        </div>
    )
}

export default TopBar;