import * as React from 'react';
import Box from '@mui/material/Box';
import SideBar from '../Home/sidebar';
import LogoutMenuDropdown from '../Home/LogoutMenuDropdown';
import NotificationIcon from '../Home/NotificationIcon';
import TopBar from '../Home/topbar';
import '../design/home.scss';
import '../design/booking.scss';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Multipleselect from '../ReUseableComponents/Multipleselect';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { 
        verifiactionDocumentSelectOptions,
        religionSelectOptionValues,
        howToFindUsSelectOptionValues,
        bloodGroupSelectOptionValues,
        intialValues,
        occupationSelectOptionValues,
        bikeParkingSelectOptionValues,
        apiClient
    } from '../Variables/Values';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const Booking = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
      }));


    const [formValues, setFormValues] = React.useState(intialValues);
    const [formErrors, setFormErrors] = React.useState({});
    const [documentFileInputnames,setDocumentFileInputnames] = React.useState(null);

    const[policeVerification,setPoliceVerification] = React.useState(null);
    const[birthCertificate,setBirthCertificate] = React.useState(null);
    const[nid,setNid] = React.useState(null);
    const[drivingLicence,seDrivingLicence] = React.useState(null);
    const[passport,setPassport] = React.useState(null);
    const[bikePapers,setBikePapers] = React.useState(null);

  
    const handleFormValueChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const validate = (values) => {
        let errors = {};

        if (!values.employeeId) {
            errors.employeeId = "Employee ID can't be empty!";
        }

        if (!values.password) {
            errors.password = "Password can't be blank";
        } else if (values.password.length < 8) {
            errors.password = "Password must be more than 7 characters!";
        }

        if (!values.otp) {
            errors.otp = "OTP can't be blank!";
        }
            return errors;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
    };

    function documentSelectOnchange (){
        setDocumentFileInputnames((localStorage.getItem('MultipleSelectOptions')).split(','));
    }

    React.useEffect(() => {
        setDocumentFileInputnames(documentFileInputnames);
        setItemNames();
    },[documentFileInputnames])

    function setItemNames(){
        if(documentFileInputnames != null){
                setPoliceVerification(null)
                setBirthCertificate(null)
                setNid(null)
                setPassport(null)
                setBikePapers(null)
                seDrivingLicence(null)
            documentFileInputnames.map((name) => {
                switch(name) {
                    case 'Police Verification':
                        setPoliceVerification('Police Verification') 
                      break;
                    case 'Birth Certificate':
                        setBirthCertificate('Birth Certificate')
                      break;
                      case 'NID':
                        setNid('NID') 
                      break;
                      case 'Driving Licence':
                        seDrivingLicence('Driving Licence') 
                      break;
                      case 'Passport':
                        setPassport('Passport') 
                      break;
                      case 'Bike Papers':
                        setBikePapers('Bike Papers') 
                      break;
                    default:
                        setPoliceVerification(null)
                        setBirthCertificate(null)
                        setNid(null)
                        setPassport(null)
                        setBikePapers(null)
                        seDrivingLicence(null)
                  }
             })
        }else{

        }
    }

    const [religionDropDownOpen, setReligionDropDownOpen] = React.useState(false);
    const handleCloseReligionDropDown = () => {setReligionDropDownOpen(false)}
    const handleOpenReligionDropDown = () => {setReligionDropDownOpen(true)}

    const [howToFIndUsDropDownOpen, sethowToFIndUsDropDownOpen] = React.useState(false);
    const handleClosehowToFIndUsDropDown = () => {sethowToFIndUsDropDownOpen(false)}
    const handleOpenhowToFIndUsDropDown = () => {sethowToFIndUsDropDownOpen(true)}

    const [occupationDropDownOpen, setoccupationDropDownOpen] = React.useState(false);
    const handleCloseoccupationDropDown = () => {setoccupationDropDownOpen(false)}
    const handleOpenoccupationDropDown = () => {setoccupationDropDownOpen(true)}

    const [bloodGroupDropDownOpen, setbloodGroupDropDownOpen] = React.useState(false);
    const handleClosebloodGroupDropDown = () => {setbloodGroupDropDownOpen(false)}
    const handleOpenbloodGroupDropDown = () => {setbloodGroupDropDownOpen(true)}

    const [branchNameDropDownOpen, setbranchNameDropDownOpen] = React.useState(false);
    const handleClosebranchNameDropDown = () => {setbranchNameDropDownOpen(false)}
    const handleOpenbranchNameDropDown = () => {setbranchNameDropDownOpen(true)}

    const [packageCategoryDropDownOpen, setpackageCategoryDropDownOpen] = React.useState(false);
    const handleClosepackageCategoryDropDown = () => {setpackageCategoryDropDownOpen(false)}
    const handleOpenpackageCategoryDropDown = () => {setpackageCategoryDropDownOpen(true)}

    const [membershipTypeDropDownOpen, setmembershipTypeDropDownOpen] = React.useState(false);
    const handleClosemembershipTypeDropDown = () => {setmembershipTypeDropDownOpen(false)}
    const handleOpenmembershipTypeDropDown = () => {setmembershipTypeDropDownOpen(true)}

    const [bedTypeDropDownOpen, setbedTypeDropDownOpen] = React.useState(false);
    const handleClosebedTypeDropDown = () => {setbedTypeDropDownOpen(false)}
    const handleOpenbedTypeDropDown = () => {setbedTypeDropDownOpen(true)}

    const [motobikeParkingDropDownOpen, setmotobikeParkingDropDownOpen] = React.useState(false);
    const handleClosemotobikeParkingDropDown = () => {setmotobikeParkingDropDownOpen(false)}
    const handleOpenmotobikeParkingDropDown = () => {setmotobikeParkingDropDownOpen(true)}

    const [lockerDropDownOpen, setlockerDropDownOpen] = React.useState(false);
    const handleCloseLockerDropDown = () => {setlockerDropDownOpen(false)}
    const handleOpenLockerDropDown = () => {setlockerDropDownOpen(true)}

    const [phoneNumberReadOnlyStatus,setphoneNumberReadonlyStatus] = React.useState(false);
    const [emailReadOnlyStatus,setemailReadonlyStatus] = React.useState(false);
    const [NidNumberReadOnlyStatus,setNidNumberReadonlyStatus] = React.useState(false);
    const [fullNameReadOnlyStatus,setfullNameReadonlyStatus] = React.useState(false);
    const [religionReadOnlyStatus,setreligionReadonlyStatus] = React.useState(false);
    const [howToFIndUsReadOnlyStatus,sethowToFIndUsReadonlyStatus] = React.useState(false);
    const [referenceIdReadOnlyStatus,setreferenceIdReadonlyStatus] = React.useState(false);
    const [imageReadOnlyStatus,setimageReadonlyStatus] = React.useState(false);
    const [fathersNameReadOnlyStatus,setfathersNameReadonlyStatus] = React.useState(false);
    const [emergencyContactNameReadOnlyStatus,setemergencyContactNameReadonlyStatus] = React.useState(false);
    const [emergencyNumberReadOnlyStatus,setemergencyNumberReadonlyStatus] = React.useState(false);
    const [occupationReadOnlyStatus,setoccupationReadonlyStatus] = React.useState(false);
    const [bloodGroupReadOnlyStatus,setbloodGroupReadonlyStatus] = React.useState(false);
    const [memberTypeReadOnlyStatus,setmemberTypeReadonlyStatus] = React.useState(false);
    const [addressReadOnlyStatus,setaddressReadonlyStatus] = React.useState(false);
    const [remarksReadOnlyStatus,setremarksReadonlyStatus] = React.useState(false);
    const [BookingDocumentMultipleSelectReadOnlyStatus,setBookingDocumentMultipleSelectReadonlyStatus] = React.useState(false);
    const [policeVerificationReadOnlyStatus,setpoliceVerificationReadonlyStatus] = React.useState(false);
    const [birthCertificateReadOnlyStatus,setbirthCertificateReadonlyStatus] = React.useState(false);
    const [nidReadOnlyStatus,setnidReadonlyStatus] = React.useState(false);
    const [drivingLicenceReadOnlyStatus,setdrivingLicenceReadonlyStatus] = React.useState(false);
    const [passportReadOnlyStatus,setpassportReadonlyStatus] = React.useState(false);
    const [bikePapersReadOnlyStatus,setbikePapersReadonlyStatus] = React.useState(false);
    const [branchNameReadOnlyStatus,setbranchNameReadonlyStatus] = React.useState(false);
    const [packageCategoryReadOnlyStatus,setpackageCategoryReadonlyStatus] = React.useState(false);
    const [checkInDateReadOnlyStatus,setcheckInDateReadonlyStatus] = React.useState(false);
    const [membershipTypeReadOnlyStatus,setmembershipTypeReadonlyStatus] = React.useState(false);
    const [bedTypeReadOnlyStatus,setbedTypeReadonlyStatus] = React.useState(false);
    const [selectedBedReadOnlyStatus,setselectedBedReadonlyStatus] = React.useState(true);
    const [motobikeParkingReadOnlyStatus,motobikeParkingReadonlyStatus] = React.useState(false);
    const [lockerReadOnlyStatus,setlockerReadonlyStatus] = React.useState(false);
    const [selectedLockersReadOnlyStatus,setselectedLockersReadonlyStatus] = React.useState(true);
    const [checkOutDateReadOnlyStatus,setcheckOutDateReadonlyStatus] = React.useState(false);
    const [checkOutInfoReadOnlyStatus,setcheckOutInfoReadonlyStatus] = React.useState(false);
    const [secutityDepositeReadOnlyStatus,setsecutityDepositeReadonlyStatus] = React.useState(true);

    const[imageProgressPercent,setImageProgressPercent] = React.useState(null);
    const[nidProgressPercent,setNidProgressPercent] = React.useState(null);
    const[policeVerificationProgressPercent,setPoliceVerificationProgressPercent] = React.useState(null);
    const[birthCertificateProgressPercent,setBirthCertificateProgressPercent] = React.useState(null);
    const[drivingLicenceProgressPercent,setDrivingLicenceProgressPercent] = React.useState(null);
    const[passportProgressPercent,setPassportProgressPercent] = React.useState(null);
    const[bikePapersProgressPercent,setBikePapersProgressPercent] = React.useState(null);

    React.useEffect(() => {
        imageProgressPercent > 99 ? setImageProgressPercent(100) : setImageProgressPercent(imageProgressPercent);
        nidProgressPercent > 99 ? setNidProgressPercent(100) : setNidProgressPercent(nidProgressPercent);
        policeVerificationProgressPercent > 99 ? setPoliceVerificationProgressPercent(100) : setPoliceVerificationProgressPercent(policeVerificationProgressPercent);
        birthCertificateProgressPercent > 99 ? setBirthCertificateProgressPercent(100) : setBirthCertificateProgressPercent(birthCertificateProgressPercent);
        drivingLicenceProgressPercent > 99 ? setDrivingLicenceProgressPercent(100) : setDrivingLicenceProgressPercent(drivingLicenceProgressPercent);
        passportProgressPercent > 99 ? setPassportProgressPercent(100) : setPassportProgressPercent(passportProgressPercent);
        bikePapersProgressPercent > 99 ? setBikePapersProgressPercent(100) : setBikePapersProgressPercent(bikePapersProgressPercent);
        console.log(imageProgressPercent)
    }, [imageProgressPercent,
        nidProgressPercent,
        policeVerificationProgressPercent,
        birthCertificateProgressPercent,
        drivingLicenceProgressPercent,
        passportProgressPercent,
        bikePapersProgressPercent
    ])

    function sendImageFunction(fileName) {
        const fileUploadProgress = {
        onUploadProgress : (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor(loaded *100 / total)
            // console.log(percent)
            if(fileName == 'image'){
                setImageProgressPercent(parseInt(percent));
            }else if(fileName == 'policeVerification'){
                setPoliceVerificationProgressPercent(parseInt(percent));
            }else if(fileName == 'birthCertificate'){
                setBirthCertificateProgressPercent(parseInt(percent));
            }else if(fileName == 'nid'){
                setNidProgressPercent(parseInt(percent));
            }else if(fileName == 'drivingLicence'){
                setDrivingLicenceProgressPercent(parseInt(percent));
            }else if(fileName == 'passport'){
                setPassportProgressPercent(parseInt(percent));
            }else if(fileName == 'bikePapers'){
                setBikePapersProgressPercent(parseInt(percent));
            }
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            }
        }

        const formData = new FormData();
        const imagefile = document.querySelector('#'+fileName);
        formData.append("file", imagefile.files[0]);
        apiClient.post('file-upload',formData,fileUploadProgress
        ).then(function (response) {
            // console.log(imageProgressPercent)
            }).catch(function (error) {
                alert(error.message);
            });
    }

    const [alreadyGotThisMember,setAlreadyGotThisMember] = React.useState(false);
    React.useEffect(() => {
        if(alreadyGotThisMember === false){
            if(formValues.phoneNumber !== null || formValues.email !== null || formValues.nid !== null){
                if(formValues.phoneNumber.length > 9 || formValues.nid > 9 || formValues.email > 5){
                    fetchOldMember();
                }
            }
        }
    },[formValues.phoneNumber,formValues.nid,formValues.email]);

    function fetchOldMember() {
        const data = {
            'nid' : formValues.nid,
            'email' : formValues.email,
            'phone_number' : formValues.phoneNumber,
        }
        apiClient.post('check-member', data, {
            headers: {
                'Accept': 'application/json'
                }
          }).then(function (response) {
            if(response.status == 200 && response.data !== 'Already this member has been booked'){
                const data = response.data;
                setAlreadyGotThisMember(true);
                console.log(data.h_t_f_u)
                setFormValues({'phoneNumber': data.phone_number });
                setFormValues({'email': data.email });

                if(isNaN(data.nid) === false){
                    setFormValues({'NidNumber': data.nid });
                }
                setFormValues({'fullName': data.full_name });
                setFormValues({ 'memberType': 'OLD' });      
                setFormValues({ 'fathersName': data.father_name });       
                setFormValues({ 'emergencyContactName': data.emergency_number_1 });     
                setFormValues({ 'emergencyNumber': data.emergency_number_2 });     
                setFormValues({ 'address': data.address });     

                setphoneNumberReadonlyStatus(true);
                setemailReadonlyStatus(true);
                if(isNaN(response.data.nid) === true){
                    setNidNumberReadonlyStatus(true)
                }
                setmemberTypeReadonlyStatus(true);
                setfullNameReadonlyStatus(true);
                setreferenceIdReadonlyStatus(true);
                setfathersNameReadonlyStatus(true);
            }
        }).catch(function (error) {
            // console.log(error.response);
        });
    }

            return (
                <Box className="Home">
                    <SideBar/>
                    <Box className="Main">
                        <LogoutMenuDropdown/>
                        <NotificationIcon/>
                        <TopBar/>
                        <Box className='Booking'>
                            <Box sx={{ bgcolor: 'background.paper', width: '100%',height: '100%' }}>
                                <Typography variant='h4' sx={{textAlign:'center',padding:'20px',fontWeight:900,color:'#c72c74',bgcolor:'rgb(227, 231, 241)',width:'600px',margin:'auto',borderRadius:'15px',marginTop:'2vh'}}>
                                    BOOKING
                                </Typography>
                                <Box className="basicInfoFormContainer">
                                    <Box className='basicInfoFormHolder'>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="phoneNumber" 
                                                        name='phoneNumber'
                                                        value={formValues.phoneNumber}
                                                        label="Phone Number" 
                                                        type="text"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}
                                                        inputProps={
                                                            { readOnly: phoneNumberReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="email" 
                                                        name='email'
                                                        value={formValues.email}
                                                        label="Email" 
                                                        
                                                        type="search" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                        
                                                        inputProps={
                                                            { readOnly: emailReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="NidNumber" 
                                                        name='NidNumber'
                                                        value={formValues.NidNumber}
                                                        label="NID Number" 
                                                        type="search" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                        
                                                        inputProps={
                                                            { readOnly: NidNumberReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="fullName" 
                                                        name='fullName'
                                                        value={formValues.fullName}
                                                        label="Full Name" 
                                                        type="search" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                        
                                                        inputProps={
                                                            { readOnly: fullNameReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Religion</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='religion'
                                                    name='religion'
                                                    open={religionDropDownOpen}
                                                    onClose={handleCloseReligionDropDown}
                                                    onOpen={handleOpenReligionDropDown}
                                                    label="Religion"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                    
                                                    readOnly={religionReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Religion
                                                        </MenuItem>
                                                        {religionSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                            id={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                 <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">How to find us</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='howToFIndUs'
                                                    name='howToFIndUs'
                                                    open={howToFIndUsDropDownOpen}
                                                    onClose={handleClosehowToFIndUsDropDown}
                                                    onOpen={handleOpenhowToFIndUsDropDown}
                                                    label="How to find us"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                    
                                                    readOnly={howToFIndUsReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            How to find us
                                                        </MenuItem>
                                                        {howToFindUsSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                            id={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="referenceId" 
                                                        name='referenceId'
                                                        value={formValues.referenceId}
                                                        label="Reference ID" 
                                                        type="number"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                        
                                                        inputProps={
                                                            { readOnly: referenceIdReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={imageProgressPercent} 
                                                        className={
                                                            imageProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="image" 
                                                        name='image'
                                                        label="Image" 
                                                        type="file"
                                                        
                                                        value={formValues.image}
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('image');
                                                            setImageProgressPercent(null)
                                                        }}

                                                        inputProps={
                                                            { readOnly: imageReadOnlyStatus, }
                                                        } 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="fathersName" 
                                                        name='fathersName'
                                                        value={formValues.fathersName}
                                                        label="Fathers Name" 
                                                        type="text"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                    
                                                        inputProps={
                                                            { readOnly: fathersNameReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }} 
                                                    />   
                                                </FormControl>                                         
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField sx={{width:'100%'}} 
                                                        id="emergencyContactName" 
                                                        name='emergencyContactName'
                                                        value={formValues.emergencyContactName}
                                                        label="Emergency Contact Name" 
                                                        type="text"
                                                        onChange={handleFormValueChange} 
                                                        inputProps={
                                                            { readOnly: emergencyContactNameReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />  
                                                </FormControl>                                          
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="emergencyNumber" 
                                                        name='emergencyNumber'
                                                        value={formValues.emergencyNumber}
                                                        label="Emergency Contact Number" 
                                                        
                                                        type="number"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                        
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={
                                                            { readOnly: emergencyNumberReadOnlyStatus, }
                                                        }
                                                    /> 
                                                </FormControl>                                           
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Occupation</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='occupation'
                                                    name='occupation'
                                                    open={occupationDropDownOpen}
                                                    onClose={handleCloseoccupationDropDown}
                                                    onOpen={handleOpenoccupationDropDown}
                                                    value={formValues.occupation}
                                                    label="How to find us"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                  
                                                    readOnly={occupationReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Occupation
                                                        </MenuItem>
                                                        {occupationSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Blood Group</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='bloodGroup'
                                                    name='bloodGroup'
                                                    open={bloodGroupDropDownOpen}
                                                    onClose={handleClosebloodGroupDropDown}
                                                    onOpen={handleOpenbloodGroupDropDown}
                                                    value={formValues.bloodGroup}
                                                    label="Blood Group"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                    
                                                    readOnly={bloodGroupReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Blood Group
                                                        </MenuItem>
                                                        {bloodGroupSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="memberType" 
                                                        name='memberType'
                                                        label="Member Type" 
                                                        type="text"
                                                        value={formValues.memberType}
                                                        inputProps={
                                                            { readOnly: memberTypeReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    /> 
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="address" 
                                                        name='address'
                                                        value={formValues.address}
                                                        label="Address" 
                                                        type="text"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                  
                                                        inputProps={
                                                            { readOnly: addressReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />   
                                                </FormControl>                                         
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        sx={{width:'100%'}} 
                                                        id="remarks" 
                                                        name='remarks'
                                                        value={formValues.remarks}
                                                        label="Remarks" 
                                                        type="text"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }} 
                                                        inputProps={
                                                            { readOnly: remarksReadOnlyStatus, }
                                                        } 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>                                            
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <Multipleselect 
                                                    title="Select Document Type"
                                                    options = {verifiactionDocumentSelectOptions}
                                                    id="BookingDocumentMultipleSelect"
                                                    name="BookingDocumentMultipleSelect"
                                                    functionOne={documentSelectOnchange}
                                                    readOnly={BookingDocumentMultipleSelectReadOnlyStatus}
                                                 />                                           
                                            </Box>
                                            <Box 
                                                className={policeVerification != null ? 'displayBlock BookingTextFieldHolder' : 'displayNone' }
                                            >
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={policeVerificationProgressPercent} 
                                                        className={
                                                            policeVerificationProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="policeVerification" 
                                                        name="policeVerification"
                                                        label="Police Verification" 
                                                        type="file"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('policeVerification');
                                                            setPoliceVerificationProgressPercent(null)
                                                        }}
                                                        inputProps={
                                                            { readOnly: policeVerificationReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box 
                                                className={birthCertificate != null ? 'displayBlock BookingTextFieldHolder' : 'displayNone' }
                                            >
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={birthCertificateProgressPercent} 
                                                        className={
                                                            birthCertificateProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="birthCertificate" 
                                                        name='birthCertificate'
                                                        label="Birth Certificate" 
                                                        type="file"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('birthCertificate');
                                                            setBirthCertificateProgressPercent(null)
                                                        }}
                                                        inputProps={
                                                            { readOnly: birthCertificateReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box 
                                                className={nid != null ? 'displayBlock BookingTextFieldHolder' : 'displayNone' }
                                            >
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={nidProgressPercent} 
                                                        className={
                                                            nidProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="nid" 
                                                        name='nid'
                                                        label="NID" 
                                                        type="file"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('nid');
                                                            setNidProgressPercent(null)
                                                        }}
                                                        inputProps={
                                                            { readOnly: nidReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box 
                                                className={drivingLicence != null ? 'displayBlock BookingTextFieldHolder' : 'displayNone' }
                                            >
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={drivingLicenceProgressPercent} 
                                                        className={
                                                            drivingLicenceProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="drivingLicence" 
                                                        name='drivingLicence'
                                                        label="Driving Licence" 
                                                        type="file"
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('drivingLicence');
                                                            setDrivingLicenceProgressPercent(null)
                                                        }} 
                                                        inputProps={
                                                            { readOnly: drivingLicenceReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box 
                                                className={passport != null ? 'displayBlock BookingTextFieldHolder' : 'displayNone' }
                                            >
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={passportProgressPercent} 
                                                        className={
                                                            passportProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="passport" 
                                                        name='passport'
                                                        label="Passport" 
                                                        type="file" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('passport');
                                                            setPassportProgressPercent(null)
                                                        }}
                                                        inputProps={
                                                            { readOnly: passportReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box 
                                                className={bikePapers != null ? 'displayBlock BookingTextFieldHolder' : 'displayNone' }
                                            >
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <BorderLinearProgress 
                                                        variant="determinate" value={bikePapersProgressPercent} 
                                                        className={
                                                            bikePapersProgressPercent === null ? 'displayNone' : 'displayBlock'
                                                        }
                                                    />
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="bikePapers" 
                                                        name='bikePapers'
                                                        label="Bike Papers" 
                                                        type="file" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                            sendImageFunction('bikePapers');
                                                            setBikePapersProgressPercent(null)
                                                        }}
                                                        inputProps={
                                                            { readOnly: bikePapersReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Branch Name</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='branchName'
                                                    name='branchName'
                                                    open={branchNameDropDownOpen}
                                                    onClose={handleClosebranchNameDropDown}
                                                    onOpen={handleOpenbranchNameDropDown}
                                                    value={formValues.branchName}
                                                    label="Blood Group"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                     
                                                    readOnly={branchNameReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Branch Name
                                                        </MenuItem>
                                                        {religionSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Package Category</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='packageCategory'
                                                    name='packageCategory'
                                                    open={packageCategoryDropDownOpen}
                                                    onClose={handleClosepackageCategoryDropDown}
                                                    onOpen={handleOpenpackageCategoryDropDown}
                                                    value={formValues.packageCategory}
                                                    label="Blood Group"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                     
                                                    readOnly={packageCategoryReadOnlyStatus} 
                                                    >
                                                        <MenuItem>
                                                            Package Category
                                                        </MenuItem>
                                                        {religionSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="checkInDate" 
                                                        name='checkInDate'
                                                        value={formValues.checkInDate}
                                                        label="Check-In Date" 
                                                        type="date" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }}                                                  
                                                        inputProps={
                                                            { readOnly: checkInDateReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Membership Type</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='membershipType'
                                                    name='membershipType'
                                                    open={membershipTypeDropDownOpen}
                                                    onClose={handleClosemembershipTypeDropDown}
                                                    onOpen={handleOpenmembershipTypeDropDown}
                                                    value={formValues.membershipType}
                                                    label="Membership Type"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}                                                     
                                                    readOnly={membershipTypeReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Membership Type
                                                        </MenuItem>
                                                        {religionSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Bed Type</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='bedType'
                                                    name='bedType'
                                                    open={bedTypeDropDownOpen}
                                                    onClose={handleClosebedTypeDropDown}
                                                    onOpen={handleOpenbedTypeDropDown}
                                                    value={formValues.bedType}
                                                    label="Bed Type"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }} 
                                                    readOnly={bedTypeReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Bed Type
                                                        </MenuItem>
                                                        {religionSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="selectedBed" 
                                                        name='selectedBed'
                                                        label="Selected Bed" 
                                                        type="text" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }} 
                                                        inputProps={
                                                            { readOnly: selectedBedReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Motobike Parking</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='motobikeParking'
                                                    name='motobikeParking'
                                                    open={motobikeParkingDropDownOpen}
                                                    onClose={handleClosemotobikeParkingDropDown}
                                                    onOpen={handleOpenmotobikeParkingDropDown}
                                                    value={formValues.motobikeParking}
                                                    label="Motobike Parking"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }} 
                                                    readOnly={motobikeParkingReadOnlyStatus} 
                                                    >
                                                        <MenuItem>
                                                            MotoBike Parking
                                                        </MenuItem>
                                                        {bikeParkingSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'>
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <InputLabel id="demo-controlled-open-select-label">Locker</InputLabel>
                                                    <Select
                                                    labelId="demo-controlled-open-select-label"
                                                    id='locker'
                                                    name='locker'
                                                    open={lockerDropDownOpen}
                                                    onClose={handleCloseLockerDropDown}
                                                    onOpen={handleOpenLockerDropDown}
                                                    value={formValues.locker}
                                                    label="Locker"
                                                    onChange={e => {
                                                        handleFormValueChange(e); 
                                                    }}  
                                                    readOnly={lockerReadOnlyStatus}
                                                    >
                                                        <MenuItem>
                                                            Locker
                                                        </MenuItem>
                                                        {bikeParkingSelectOptionValues.map((option) => (
                                                        <MenuItem 
                                                            key={option} 
                                                            value={option}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="selectedLockers" 
                                                        name='selectedLockers'
                                                        label="Selected Lockers" 
                                                        type="text" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }} 
                                                        inputProps={
                                                            { readOnly: selectedLockersReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="checkOutDate" 
                                                        name='checkOutDate'
                                                        value={formValues.checkOutDate}
                                                        label="Check-Out Date" 
                                                        type="date" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }} 
                                                        inputProps={
                                                            { readOnly: checkOutDateReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="checkOutInfo" 
                                                        name='checkOutInfo'
                                                        label="Check-Out Info" 
                                                        type="text" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }} 
                                                        inputProps={
                                                            { readOnly: checkOutInfoReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            <Box className='BookingTextFieldHolder'> 
                                                <FormControl sx={{ m: 1, width: '100%' }}>
                                                    <TextField 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{width:'100%'}} 
                                                        id="secutityDeposite" 
                                                        name='secutityDeposite'
                                                        label="Security Deposite" 
                                                        type="text" 
                                                        onChange={e => {
                                                            handleFormValueChange(e); 
                                                        }} 
                                                        inputProps={
                                                            { readOnly: secutityDepositeReadOnlyStatus, }
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                );
            };

export default Booking;