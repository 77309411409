import * as React from 'react';
import Avatar from '@mui/material/Avatar';

export default function ProfileAvatar(props) {
  return (
      <Avatar
        alt="Remy Sharp"
        src={props.image}
        sx={{ width: '10vw', height: '10vw',marginTop:'3vh', }}
        className='profileRightAvatar'
      />
  );
}
