import React, {useState} from 'react';
import {useFormik} from 'formik';
import Modal from 'react-modal';
import Button from '@mui/material/Button';
import '../design/login.css';
import * as Yup from 'yup';
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = () => {
    const [resetPasswordModalIsOpen, setresetPasswordModalIsOpen] =
        useState(false);

    const passwordReset = async () => {
        try {
            alert('');
        } catch (error) {
            alert('');
        }
    };

    const formikPassReset = useFormik({
        initialValues: {
            email: '',
            password: '',
            otp: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            password: Yup.string()
                .min(5, 'Must be 5 characters or more')
                .max(30, 'Must be 30 characters or less')
                .required('Required'),
            otp: Yup.string()
                .min(4, 'Must be 4 characters')
                .required('Required')
        }),
        onSubmit: (values) => {
            passwordReset(values.email, values.password, values.otp);
        }
    });

    function showQrButton() {
        document.getElementById('QrButton').style.display = 'block';
    }

    function closeModal() {
        setresetPasswordModalIsOpen(false);
    }

    function openModal() {
        setresetPasswordModalIsOpen(true);
    }

    function hideQrButton() {
        document.getElementById('QrButton').style.display = 'none';
        document.getElementById('loginDivHolder').style.display = 'none';
    }

    function showLoginHolder(){
        document.getElementById('loginDivHolder').style.display = 'block';
    }

    return (
        <div className="ForgotPasswordModalHolder">
            <Modal
                isOpen={resetPasswordModalIsOpen}
                contentLabel="Example Modal"
                className="ForgotPasswordModal"
                transparent={false}
            >
                <div className="ForgotPasswordModalMainDiv">
                    <div className="card-header text-center-custom">
                        <div className="h1">
                            <b className="classForBrandText">NEWAYS</b>
                            <span className="classFor3">3</span>
                        </div>
                    </div>
                    <p className="forgotPasswordHeadline">Reset Password</p>
                    <div className="custom-card-body">
                        <ForgotPasswordForm />
                    </div>
                    <div className="ForgotPasswordCancelButtonHolder">
                        <Button
                            className="LoginButton"
                            variant="outlined"
                            type="button"
                            color="warning"
                            onClick={() => [closeModal(), showQrButton(),showLoginHolder()]}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <p className="mb-1 loginForgotPassword">
                <button
                    type="button"
                    className="ForgotPasswordLoginPageButton"
                    onClick={() => [openModal(), hideQrButton()]}
                >
                    Forgot Password ?
                </button>
            </p>
        </div>
    );
};

export default ForgotPassword;
