import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HomeIcon from '@mui/icons-material/Home';
import BadgeIcon from '@mui/icons-material/Badge';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FolderIcon from '@mui/icons-material/Folder';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCardIcon from '@mui/icons-material/AddCard';
import BalanceIcon from '@mui/icons-material/Balance';
import FlakyIcon from '@mui/icons-material/Flaky';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeselectIcon from '@mui/icons-material/Deselect';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import TopicIcon from '@mui/icons-material/Topic';
import CheckIcon from '@mui/icons-material/Check';
import PreviewIcon from '@mui/icons-material/Preview';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import PaidIcon from '@mui/icons-material/Paid';
import DraftsIcon from '@mui/icons-material/Drafts';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import PageviewIcon from '@mui/icons-material/Pageview';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import BookIcon from '@mui/icons-material/Book';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DetailsIcon from '@mui/icons-material/Details';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HandymanIcon from '@mui/icons-material/Handyman';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import SellIcon from '@mui/icons-material/Sell';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import DescriptionIcon from '@mui/icons-material/Description';
import CalculateIcon from '@mui/icons-material/Calculate';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import AddIcon from '@mui/icons-material/Add';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import PercentIcon from '@mui/icons-material/Percent';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import LockClockIcon from '@mui/icons-material/LockClock';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import SecurityIcon from '@mui/icons-material/Security';
import ShieldIcon from '@mui/icons-material/Shield';
import EuroIcon from '@mui/icons-material/Euro';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AppsIcon from '@mui/icons-material/Apps';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import InventoryIcon from '@mui/icons-material/Inventory';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import TourIcon from '@mui/icons-material/Tour';
import SmsIcon from '@mui/icons-material/Sms';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import OpacityIcon from '@mui/icons-material/Opacity';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import CategoryIcon from '@mui/icons-material/Category';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LogoutIcon from '@mui/icons-material/Logout';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import BackspaceIcon from '@mui/icons-material/Backspace';
import LtePlusMobiledataIcon from '@mui/icons-material/LtePlusMobiledata';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import EventIcon from '@mui/icons-material/Event';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PasswordIcon from '@mui/icons-material/Password';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import ShortTextIcon from '@mui/icons-material/ShortText';
import StreetviewIcon from '@mui/icons-material/Streetview';
import TodayIcon from '@mui/icons-material/Today';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import VibrationIcon from '@mui/icons-material/Vibration';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FeedIcon from '@mui/icons-material/Feed';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';

import {Link} from 'react-router-dom';
import '../design/home.scss';

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        ...other
    } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

const SideBar = () => {

    const EmployeeName = localStorage.getItem('EmployeeName');

    return (
        <TreeView
            aria-label="gmail"
            //defaultExpanded={['3']}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<Box style={{ width: 24 }} />}
            className="sideBar"
        >
            <Box style={{width:'100%'}}>
                <p className='BrandNameParagraph'   >
                    <span style={{color:'white',}}>NEWAYS</span>
                    <span style={{
                        fontSize:'15pt',
                        verticalAlign:'top',
                        }}>
                        3
                    </span>
                </p>
            </Box>
            <Box style={{width:'100%'}}>
                <Link to="/Profile" className='textDecoration'>
                    <p className='userNameParagraph'>
                        {EmployeeName}
                    </p>
                </Link>
            </Box>
            <Link to="/" className='textDecoration'>
                <StyledTreeItem nodeId="1" labelText="Home" labelIcon={HomeIcon} />
            </Link>
            <Link to="/Dashboard" className='textDecoration'>
                <StyledTreeItem nodeId="2" labelText="Dashboard" labelIcon={AssessmentIcon} />
            </Link>
            <StyledTreeItem nodeId="3" labelText="HRM" labelIcon={FolderIcon}>
                <StyledTreeItem nodeId="4" labelText="Employee Directory" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="5"
                        labelText="Employee List"
                        labelIcon={BadgeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="6"
                        labelText="Employee Add Request"
                        labelIcon={AddReactionIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="7"
                        labelText="Employee Add Form"
                        labelIcon={FormatColorTextIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="8"
                        labelText="Exit Employee Directory"
                        labelIcon={DirectionsRunIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="9" labelText="DPT: & Designation" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="10"
                        labelText="Department Designation"
                        labelIcon={LocalPoliceIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="11"
                        labelText="Designation Mangement"
                        labelIcon={ManageAccountsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="12" labelText="Payroll" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="13"
                        labelText="Set Attendence Bonus"
                        labelIcon={CardGiftcardIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="14"
                        labelText="Set Department Head"
                        labelIcon={HowToRegIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="15"
                        labelText="Salary Deduction"
                        labelIcon={RemoveCircleOutlineIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="16"
                        labelText="Extra Salary"
                        labelIcon={AddCardIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="17"
                        labelText="Salary Generate"
                        labelIcon={BalanceIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="18"
                        labelText="Employee Fired Approval"
                        labelIcon={FlakyIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="19" labelText="Attendance" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="20"
                        labelText="Attendence Form"
                        labelIcon={FormatColorTextIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="21"
                        labelText="Yearly Attendance"
                        labelIcon={DateRangeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="22"
                        labelText="Missing Attendance"
                        labelIcon={DeselectIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="23"
                        labelText="Attendance Log"
                        labelIcon={FormatListNumberedIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="24"
                        labelText="Attendance Overview"
                        labelIcon={LocationSearchingIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="25" labelText="Recruitment" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="26"
                        labelText="Add New Candidate"
                        labelIcon={AddReactionIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="27"
                        labelText="Manage Candidate"
                        labelIcon={CandlestickChartIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="28"
                        labelText="Candidate Shortlist"
                        labelIcon={ShortTextIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="29"
                        labelText="Interview"
                        labelIcon={StreetviewIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="30"
                        labelText="Candidate Selection"
                        labelIcon={CheckBoxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="31"
                        labelText="Today Candidate Attendance"
                        labelIcon={TodayIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="32"
                        labelText="Recruitment Approval Logs"
                        labelIcon={DoneAllIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="33" labelText="Advance Salary" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="34"
                        labelText="Grant Advance Salary"
                        labelIcon={PriceCheckIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="35"
                        labelText="Advance Salary Report"
                        labelIcon={AssessmentIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="36" labelText="Leave" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="37"
                        labelText="Hold Employee Logs"
                        labelIcon={BookIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="38"
                        labelText="Leave Application"
                        labelIcon={TopicIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="39" labelText="Award" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="40"
                        labelText="Employee Performance"
                        labelIcon={VibrationIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="41"
                        labelText="Approved Employee Performance"
                        labelIcon={AddModeratorIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="42"
                        labelText="Festival Award"
                        labelIcon={CelebrationIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="43" labelText="Print" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="44"
                        labelText="Employee Visiting Card"
                        labelIcon={LocalPrintshopIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="45"
                        labelText="Employee QR Code"
                        labelIcon={QrCode2Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="46"
                        labelText="Employee ID Card"
                        labelIcon={FeaturedVideoIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="47" labelText="HRM Reports" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="48"
                        labelText="Department Head Report"
                        labelIcon={FeedIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="49"
                        labelText="Attendance Bonus Report"
                        labelIcon={TopicIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="50"
                        labelText="Over-Duty Bonus Report"
                        labelIcon={SummarizeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="51"
                        labelText="Increament Report"
                        labelIcon={PlusOneIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="52"
                        labelText="Decreament Report"
                        labelIcon={BookmarkRemoveIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="53"
                        labelText="Advance Salary Report"
                        labelIcon={NaturePeopleIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="54"
                        labelText="Leave Report"
                        labelIcon={TopicIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
            </StyledTreeItem>
            <StyledTreeItem nodeId="55" labelText="Accounting" labelIcon={FolderIcon}>
                <StyledTreeItem nodeId="56" labelText="Accounts" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="57"
                        labelText="Manage Accounts"
                        labelIcon={DynamicFeedIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="58"
                        labelText="Balance Sheet"
                        labelIcon={TopicIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="59"
                        labelText="Account Statements"
                        labelIcon={ReadMoreIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="60" labelText="Transections" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="61"
                        labelText="View Transections"
                        labelIcon={CoPresentIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="62"
                        labelText="New Transections"
                        labelIcon={NewReleasesIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="63"
                        labelText="New Transfer"
                        labelIcon={AutorenewIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="64"
                        labelText="Client Transections"
                        labelIcon={LineAxisIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem nodeId="65" labelText="Petty Cash Sec." labelIcon={FolderIcon}>
                        <StyledTreeItem
                            nodeId="66"
                            labelText="Petty Cash"
                            labelIcon={CoPresentIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />
                        <StyledTreeItem
                            nodeId="67"
                            labelText="Instant Buy Something"
                            labelIcon={NewReleasesIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />
                        <StyledTreeItem
                            nodeId="68"
                            labelText="Advance Money"
                            labelIcon={AutorenewIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />
                        <StyledTreeItem
                            nodeId="69"
                            labelText="Advance Money Logs & Aproval"
                            labelIcon={CheckIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />   
                        <StyledTreeItem
                            nodeId="70"
                            labelText="Advance Return Logs & Aproval"
                            labelIcon={KeyboardReturnIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        /> 
                        <StyledTreeItem
                            nodeId="71"
                            labelText="Advance Money Overview Log"
                            labelIcon={PreviewIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />                                                                    
                    </StyledTreeItem>
                    <StyledTreeItem
                        nodeId="72"
                        labelText="Checkout Member List"
                        labelIcon={ShoppingCartCheckoutIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="73"
                        labelText="Investment Withdraw List"
                        labelIcon={PublishedWithChangesIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="74"
                        labelText="Employee Withdraw List"
                        labelIcon={PlaylistPlayIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="75"
                        labelText="Check Point"
                        labelIcon={NotListedLocationIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="76"
                        labelText="Envelop Print"
                        labelIcon={DraftsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="77"
                        labelText="Employee Salary"
                        labelIcon={PaidIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="78"
                        labelText="Checkout Old Member List"
                        labelIcon={PlaylistRemoveIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="79" labelText="Income" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="80"
                        labelText="View Income"
                        labelIcon={PageviewIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="81"
                        labelText="Add Income Category"
                        labelIcon={ControlPointDuplicateIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="82"
                        labelText="Add Income"
                        labelIcon={AddCardIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />                                                                              
                </StyledTreeItem>
                <StyledTreeItem nodeId="83" labelText="Expense" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="84"
                        labelText="View Expense"
                        labelIcon={PageviewIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="85"
                        labelText="Add Expense Category"
                        labelIcon={ControlPointDuplicateIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='86%'
                    />
                    <StyledTreeItem
                        nodeId="86"
                        labelText="Add Expense"
                        labelIcon={AddCardIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />    
                    <StyledTreeItem
                        nodeId="877"
                        labelText="Add House Rent"
                        labelIcon={PageviewIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="88"
                        labelText="Add Internet Bill"
                        labelIcon={ControlPointDuplicateIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="89"
                        labelText="Add Water Bill"
                        labelIcon={AddCardIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="90"
                        labelText="Add Electric Bill"
                        labelIcon={ElectricalServicesIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="91"
                        labelText="Add Food Cost"
                        labelIcon={ControlPointDuplicateIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="92"
                        labelText="TA/DA Bill"
                        labelIcon={LocalAtmIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />  
                    <StyledTreeItem
                        nodeId="93"
                        labelText="Employee Salary"
                        labelIcon={MonetizationOnIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />                                                                         
                </StyledTreeItem>
                <StyledTreeItem nodeId="94" labelText="Advance Accounts" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="95"
                        labelText="Chart of Account"
                        labelIcon={AccountTreeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="96"
                        labelText="Balance Adjustment"
                        labelIcon={CropRotateIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />    
                    <StyledTreeItem
                        nodeId="97"
                        labelText="Cash Adjustment"
                        labelIcon={LocalConvenienceStoreIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    /> 
                    <StyledTreeItem
                        nodeId="98"
                        labelText="Bank Adjustment"
                        labelIcon={DehazeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="99"
                        labelText="Payment Type"
                        labelIcon={ReceiptIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="100"
                        labelText="Debit Voucher"
                        labelIcon={ReceiptLongIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="101"
                        labelText="Credit Voucher"
                        labelIcon={MarkunreadMailboxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="102"
                        labelText="Contra Voucher"
                        labelIcon={RecentActorsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="103"
                        labelText="Journal Voucher"
                        labelIcon={BookIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />  
                    <StyledTreeItem
                        nodeId="104"
                        labelText="Voucher Approval"
                        labelIcon={BookmarkAddedIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />                                                                         
                </StyledTreeItem>
                <StyledTreeItem nodeId="105" labelText="Account Report" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="106"
                        labelText="Cash Book"
                        labelIcon={AccountBalanceWalletIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="107"
                        labelText="Bank Book"
                        labelIcon={AccountBalanceIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />    
                    <StyledTreeItem
                        nodeId="108"
                        labelText="Mobile Book"
                        labelIcon={InstallMobileIcon    }
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    /> 
                    <StyledTreeItem
                        nodeId="109"
                        labelText="General Ledger"
                        labelIcon={DescriptionIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="110"
                        labelText="Trial Balance"
                        labelIcon={WbIridescentIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="111"
                        labelText="Profit Loss"
                        labelIcon={CalculateIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="112"
                        labelText="Cash Flow"
                        labelIcon={MarkunreadMailboxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="113"
                        labelText="Coa Print"
                        labelIcon={RecentActorsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="114"
                        labelText="Emp: Award Insert Report"
                        labelIcon={BookIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />                                                                       
                </StyledTreeItem>
                <StyledTreeItem nodeId="115" labelText="Aproval" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="116"
                        labelText="Loan Aproval"
                        labelIcon={CheckBoxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
            </StyledTreeItem>
            <StyledTreeItem nodeId="117" labelText="Inventory" labelIcon={FolderIcon}>
                <StyledTreeItem nodeId="87" labelText="Items Manager" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="118"
                        labelText="Add Product Name"
                        labelIcon={DriveFileRenameOutlineIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="119"
                        labelText="Add New Product Details"
                        labelIcon={DetailsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="120"
                        labelText="Manage Products"
                        labelIcon={ManageAccountsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="121"
                        labelText="Add Service Product"
                        labelIcon={DesignServicesIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="122"
                        labelText="Manage Services Products"
                        labelIcon={MiscellaneousServicesIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="123" labelText="Warehouse" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="124"
                        labelText="Manage Warehouses"
                        labelIcon={HandymanIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="125"
                        labelText="Warehouse Stock"
                        labelIcon={Inventory2Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="126" labelText="Damaged/Stolen" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="127"
                        labelText="Manage Damaged Product"
                        labelIcon={HandymanIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="128"
                        labelText="Out of Order Products "
                        labelIcon={DataThresholdingIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="129"
                        labelText="Stolen Products"
                        labelIcon={FilterListOffIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="130" labelText="Requisitions" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="131"
                        labelText="Manage Requisitions"
                        labelIcon={HandymanIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="132"
                        labelText="Department Requisitions"
                        labelIcon={RequestQuoteIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="133" labelText="Purchase Order" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="134"
                        labelText="New Pre-Order"
                        labelIcon={SellIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="135"
                        labelText="Manage Pre-Order"
                        labelIcon={ManageAccountsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="136"
                        labelText="Manage Purchase Order"
                        labelIcon={HandymanIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="137"
                        labelText="Manage Food Product Order"
                        labelIcon={DinnerDiningIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="138" labelText="Suppliers" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="139"
                        labelText="Manage Suppliers"
                        labelIcon={DeliveryDiningIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="140" labelText="Products label" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="141"
                        labelText="Add Product Scales"
                        labelIcon={AddIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="142"
                        labelText="Add Additional Product Configuration"
                        labelIcon={AddModeratorIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="143"
                        labelText="Add Product Brands"
                        labelIcon={BrandingWatermarkIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
            </StyledTreeItem>
            <StyledTreeItem nodeId="144" labelText="Reports" labelIcon={FolderIcon}>
                <StyledTreeItem nodeId="116" labelText="Booking" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="145"
                        labelText="Booking Report"
                        labelIcon={AssessmentIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="146"
                        labelText="Auto Cancel"
                        labelIcon={AutoFixOffIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="147"
                        labelText="Force Cancel"
                        labelIcon={DeleteForeverIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="148"
                        labelText="Self Cancel"
                        labelIcon={CameraFrontIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="149"
                        labelText="Cancel Report"
                        labelIcon={DoNotDisturbOffIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="150"
                        labelText="Renew Report"
                        labelIcon={BrandingWatermarkIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="151"
                        labelText="Check-In Today"
                        labelIcon={AddIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="152"
                        labelText="Check-Out Today"
                        labelIcon={CancelScheduleSendIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="153"
                        labelText="Package Change Report"
                        labelIcon={BrandingWatermarkIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="154"
                        labelText="Bed Change Report"
                        labelIcon={KeyboardReturnIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="155"
                        labelText="Discount Report"
                        labelIcon={PercentIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="156"
                        labelText="Cancel Reminder"
                        labelIcon={NotificationsOffIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="157"
                        labelText="Live Occupied Report"
                        labelIcon={MarkunreadMailboxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="158"
                        labelText="Live Cancel Report"
                        labelIcon={PhonelinkEraseIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="159"
                        labelText="Live Booked Report"
                        labelIcon={BookOnlineIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="160"
                        labelText="Check-In Report"
                        labelIcon={PlaylistAddCheckCircleIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="161"
                        labelText="Package Wise Occupation"
                        labelIcon={JoinFullIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="162"
                        labelText="Package/Occupation Daily Occupied"
                        labelIcon={DataThresholdingIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="163" labelText="Collection Report" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="164"
                        labelText="Transaction Report"
                        labelIcon={AssessmentIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="165"
                        labelText="Locker Report"
                        labelIcon={LockClockIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="166"
                        labelText="Income Report"
                        labelIcon={MoveToInboxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="167"
                        labelText="Security Deposite Report"
                        labelIcon={SecurityIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="168"
                        labelText="Security Deposite Report 2"
                        labelIcon={ShieldIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="169"
                        labelText="Cash-Back Report"
                        labelIcon={EuroIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="170"
                        labelText="All Collection Report"
                        labelIcon={LibraryBooksIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="171"
                        labelText="Details Collection Report"
                        labelIcon={AppsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="172"
                        labelText="Bkash Report"
                        labelIcon={BrandingWatermarkIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="173"
                        labelText="Branch Revenue Report"
                        labelIcon={ModelTrainingIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="174"
                        labelText="Investment Collection Report"
                        labelIcon={AllInclusiveIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="175" labelText="CRM Reports" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="176"
                        labelText="Checkout Member List"
                        labelIcon={IndeterminateCheckBoxIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="177"
                        labelText="Refunded Member List"
                        labelIcon={RestoreFromTrashIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="178" labelText="Front Office" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="179"
                        labelText="Shop Report"
                        labelIcon={InventoryIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="180"
                        labelText="Dining Report"
                        labelIcon={BreakfastDiningIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="181"
                        labelText="Visitor Book Report"
                        labelIcon={TourIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="182" labelText="Communication" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="183"
                        labelText="All & Auto SMS Logs"
                        labelIcon={SmsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="184"
                        labelText="Member Corn Jobs"
                        labelIcon={RememberMeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="185"
                        labelText="Investor Corn Jobs"
                        labelIcon={OpacityIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                     <StyledTreeItem
                        nodeId="186"
                        labelText="Demo Investor Corn Jobs"
                        labelIcon={InvertColorsOffIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="187" labelText="Tracing Report" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="188"
                        labelText="All Employee Screenshot"
                        labelIcon={SmartScreenIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="189"
                        labelText="Employee Login Info"
                        labelIcon={PowerSettingsNewIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="190"
                        labelText="Member Login Info"
                        labelIcon={SettingsPowerIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem
                        nodeId="191"
                        labelText="Refreshment Items"
                        labelIcon={CategoryIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="192" labelText="My Profile" labelIcon={FolderIcon}>
                <StyledTreeItem nodeId="189" labelText="Request" labelIcon={FolderIcon}>
                    <StyledTreeItem nodeId="190" labelText="Leave Request" labelIcon={FolderIcon}>
                        <StyledTreeItem
                            nodeId="193"
                            labelText="Get Leave"
                            labelIcon={LogoutIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />
                        <StyledTreeItem
                            nodeId="194"
                            labelText="Withdraw Leave"
                            labelIcon={ExitToAppIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />
                    </StyledTreeItem>
                    <StyledTreeItem
                        nodeId="195"
                        labelText="Attendence Adjustment"
                        labelIcon={EventAvailableIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="196"
                        labelText="Advance Salary Request"
                        labelIcon={AttachMoneyIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="197"
                        labelText="TA/DA Request"
                        labelIcon={ChromeReaderModeIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="198"
                        labelText="Add Increament"
                        labelIcon={AddRoadIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="199"
                        labelText="Add Decreament"
                        labelIcon={BookmarkRemoveIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="200"
                        labelText="Product Requisition"
                        labelIcon={Inventory2Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="201"
                        labelText="Service Requisition"
                        labelIcon={ProductionQuantityLimitsIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="202"
                        labelText="Employee Performance"
                        labelIcon={DeveloperBoardIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="203"
                        labelText="Recruitment Request"
                        labelIcon={AppRegistrationIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="204"
                        labelText="Fire an Employee"
                        labelIcon={PersonRemoveAlt1Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="205"
                        labelText="Petty Cash Request"
                        labelIcon={LocalAtmIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="206"
                        labelText="Purchase Money Request"
                        labelIcon={AddShoppingCartIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="207"
                        labelText="Subordinate Leave Request"
                        labelIcon={ContentPasteGoIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="208"
                        labelText="Resign Request"
                        labelIcon={BackspaceIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="209"
                        labelText="Increase Mobile Allowance"
                        labelIcon={LtePlusMobiledataIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="210" labelText="Approval" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="211"
                        labelText="Attendence Request Approval"
                        labelIcon={EventAvailableIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="212"
                        labelText="Service Requisition Approval"
                        labelIcon={HomeRepairServiceIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="213"
                        labelText="Leave Approval"
                        labelIcon={ExitToAppIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="214"
                        labelText="Inc/Dec Approval"
                        labelIcon={AddRoadIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="215"
                        labelText="Advance Salary Approval"
                        labelIcon={BookmarkRemoveIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="216"
                        labelText="Performance Approval"
                        labelIcon={Inventory2Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="217"
                        labelText="Employee Exit Approval"
                        labelIcon={PersonRemoveAlt1Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="218"
                        labelText="Employee Fire Approval"
                        labelIcon={PersonRemoveAlt1Icon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="219"
                        labelText="Mobile Allowance Approval"
                        labelIcon={LtePlusMobiledataIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="220" labelText="My Expense" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="221"
                        labelText="Urgent Expense & Return"
                        labelIcon={ImportExportIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="222"
                        labelText="Urgent Expense List"
                        labelIcon={FeaturedPlayListIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="223"
                        labelText="Urgent Return Logs"
                        labelIcon={KeyboardReturnIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="224" labelText="Department Inventory" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="225"
                        labelText="Manage Received Requisitions"
                        labelIcon={ImportExportIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="226"
                        labelText="Manage Sent Requisitions"
                        labelIcon={FeaturedPlayListIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="227"
                        labelText="Department Stock"
                        labelIcon={KeyboardReturnIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="228"
                        labelText="Department Status"
                        labelIcon={FeaturedPlayListIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="229"
                        labelText="Manage Assigned Services"
                        labelIcon={KeyboardReturnIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem
                    nodeId="230"
                    labelText="My Attendents"
                    labelIcon={EventIcon}
                    // labelInfo="90"
                    color="#030001"
                    bgColor="#030001"
                    width='80%'
                />
                <StyledTreeItem
                    nodeId="231"
                    labelText="Subordinate Attendents"
                    labelIcon={PermContactCalendarIcon}
                    // labelInfo="90"
                    color="#030001"
                    bgColor="#030001"
                    width='80%'
                />
                <StyledTreeItem
                    nodeId="232"
                    labelText="Change Password"
                    labelIcon={PasswordIcon}
                    // labelInfo="90"
                    color="#030001"
                    bgColor="#030001"
                    width='80%'
                />
                <StyledTreeItem
                    nodeId="233"
                    labelText="Change Profile Picture"
                    labelIcon={AssignmentIndIcon}
                    // labelInfo="90"
                    color="#030001"
                    bgColor="#030001"
                    width='80%'
                />
            </StyledTreeItem>
            <StyledTreeItem nodeId="234" labelText="Create" labelIcon={FolderIcon}>
                <StyledTreeItem nodeId="235" labelText="Branch,Bed e.t.c" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="236"
                        labelText="Manage Branch"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="237"
                        labelText="Manage Floor"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="238"
                        labelText="Manage Unit"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="239"
                        labelText="Manage Rooms"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="240"
                        labelText="Manage Column"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="241"
                        labelText="Manage Beds"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="242"
                        labelText="Manage Room Types"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="243"
                        labelText="Manage Locker Types"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="244"
                        labelText="Manage Locker"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
                <StyledTreeItem nodeId="245" labelText="HRM" labelIcon={FolderIcon}>
                    <Link to="/ManageRoles" className='textDecoration'>
                        <StyledTreeItem
                            nodeId="246"
                            labelText="Manage Roles"
                            labelIcon={AssignmentIndIcon}
                            // labelInfo="90"
                            color="#030001"
                            bgColor="#030001"
                            width='80%'
                        />
                    </Link>
                    <StyledTreeItem
                        nodeId="247"
                        labelText="Manage Department"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="248"
                        labelText="Manage Designation"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="249"
                        labelText="Manage Grade"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="250"
                        labelText="Leave Tyes"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="251"
                        labelText="Attendance Missing Reason"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
            </StyledTreeItem>
            <StyledTreeItem nodeId="252" labelText="Booking" labelIcon={FolderIcon}>
                <Link to="/Booking" className='textDecoration'>
                    <StyledTreeItem
                        nodeId="253"
                        labelText="Booking"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </Link>
                <StyledTreeItem nodeId="254" labelText="Target Setup" labelIcon={FolderIcon}>
                    <StyledTreeItem
                        nodeId="255"
                        labelText="Occuopancy Target Setup"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="256"
                        labelText="Award Point Target Setup"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="257"
                        labelText="Investor Facility Setup"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                    <StyledTreeItem
                        nodeId="258"
                        labelText="Investor IPO Commission Setup"
                        labelIcon={AssignmentIndIcon}
                        // labelInfo="90"
                        color="#030001"
                        bgColor="#030001"
                        width='80%'
                    />
                </StyledTreeItem>
            </StyledTreeItem>
        </TreeView>
    );
};

export default SideBar;