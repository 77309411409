import * as React from 'react';
import '../../design/profile.scss';
import Box from '@mui/material/Box';
import LogoutMenuDropdown from '../LogoutMenuDropdown';
import NotificationIcon from '../NotificationIcon';
import Sidebar from "../sidebar";
import ProfileLeft from './Profile/ProfileLeft';
import ProfileRight from './Profile/ProfileRight';
import TopBar from '../topbar';
// import TopBar from "./topbar";

const Profile = () => {
    return (
        <Box className="Home">
            <Sidebar/>
            <Box className="Main">
                {/* <TopBar/> */}
                <LogoutMenuDropdown/>
                <NotificationIcon/>
                <TopBar/>
                <Box className='ProfileHolder'>
                    <ProfileRight/>
                    <ProfileLeft/>
                </Box>
            </Box>
        </Box>
    );
};

export default Profile;