import '../../../design/profile.scss';
import ProfileAvatar from './ProfileAvatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {apiClient} from '../../../Variables/Values';
import React, { useState,useEffect } from "react";
import Skeleton from '@mui/material/Skeleton';



export default function ProfileRight(){
    const [basicInfos, setBasicInfos] = useState({});
    const userToken = localStorage.getItem('EmployeeToken');
    const [pageloading,setPageLoading] = useState(false);
    const name = localStorage.getItem('EmployeeName');

    useEffect(() => {
        setPageLoading(true);
        apiClient.get('sanctum/csrf-cookie')
        .then(() => apiClient.get('short_single_employee')
            .then(response => {
                setBasicInfos(response.data);
                setPageLoading(false);
            }))
            .catch((error) => {
                setPageLoading(false);
            });
    },[]);
  
    return(
            pageloading === false ?
            <Box className="ProfileRight">
                    <ProfileAvatar image={basicInfos.photo}/>
                    <Typography variant='h6'>
                        {name}
                    </Typography>
                    <Typography variant='h6'>
                        {userToken}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {basicInfos.designation}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {basicInfos.employee_id}
                    </Typography>
                <Box>
                    <Typography className='ProfileRightBasicInfoFlexAlign' variant='subtitle1' >
                        Grade : {basicInfos.grade }
                    </Typography>
                    <Typography variant='subtitle1'>
                        Branch Name : {basicInfos.branch_name}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Department : {basicInfos.department}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Role : {basicInfos.role}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Email : {basicInfos.email}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Personal Phone : {basicInfos.personal_phone}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Company Phone : {basicInfos.company_phone}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Marital Status : {basicInfos.marital_status}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Date of Joining : {basicInfos.date_of_joining}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Duration : {basicInfos.duration}
                    </Typography>
                </Box>
            </Box>
            :
            <Box className="ProfileRight">
                <ProfileAvatar/>
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
                <Skeleton animation="wave" width={'80%'} height={30} />
            </Box>
    )
}